import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../content/Layout"
import SEO from "../content/Seo"
import { Fragment, useState } from "react"
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import { ChevronDownIcon } from "@heroicons/react/solid"
import FilterIcon from "../icons/filter"
import Pager from "../content/Pager";

const sortOptions = [
  { name: "Featured", value: "FEATURED" },
  { name: "Date", value: "PRICE" },
  { name: "Name", value: "" },
  { name: "New", value: "CREATED_AT" },
  { name: "Trending", value: "BEST_SELLING" },
]
const defaultFilters = [
  {
    id: "category",
    name: "Category",
    options: [
      { value: "crystals", label: "Crystals" },
      { value: "jewellery", label: "Jewellery" },
      { value: "aromatherapy", label: "Aromatherapy" },
      { value: "books", label: "Books" },
      { value: "tarot", label: "Tarot & Oracle" },
      { value: "accessories", label: "Accessories" },
    ],
  },
  {
    id: "tags",
    name: "Tags",
    options: [
      { value: "clothing-company", label: "Clothing Company" },
      { value: "fashion-inc", label: "Fashion Inc." },
      { value: "shoes-n-more", label: "Shoes 'n More" },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const Archive = ({ articles, pageContext }) => {
  const [open, setOpen] = useState(false)
  return (
    <Layout>
      <SEO title="Archive" slug="/articles" image="../assets/Header Image Placeholder.jpg" />
      <main className="pt-36">
        <div className="container max-w-screen-2xl mx-auto px-4 my-12 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-24">
            {articles.map((article) => {
              return (
                <Link
                  key={article.id}
                  to={`/${article.title
                    .toLowerCase()
                    .replaceAll("!", "")
                    .replace(/[:.'() ]/g, "-")
                    .replaceAll("--", "-")
                    .replaceAll("--", "-")
                    .replaceAll("?-", "-")}`}
                  className="p-4 w-full  mb-6 relative hover:bg-ec-sky/10 rounded-tr-[100px] transition"
                >
                  <div>
                    <div className="h-96 overflow-hidden hover:opacity-90 transition rounded-tr-[100px] rounded-bl-[100px]">
                      <GatsbyImage
                        alt="content"
                        className="object-cover object-center h-full w-full"
                        image={article.image.gatsbyImageData}
                      />
                    </div>
                  </div>
                  <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1 mt-6">
                    {article.date}
                  </h2>
                  <h2 className="text-3xl font-medium font-heading text-ec-earth mt-5 max-h-20 overflow-hidden">
                    {article.title}
                  </h2>
                  <p className="font-body text-ec-sea text-lg leading-relaxed mt-2 mb-3 h-20 overflow-hidden">
                    {`${article.description.description.slice(0, 120)}...`}
                  </p>
                </Link>
              )
            })}
          </div>
          <Pager pageContext={pageContext}/>
        </div>
      </main>
    </Layout>
  )
}

export default Archive
