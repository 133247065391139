import React from "react";
import Archive from "./Archive";
import { graphql } from "gatsby";

const Articles = ({ data, pageContext }) => {
  const articles = data.allContentfulArticle.nodes;
  return <Archive articles={articles} pageContext = {pageContext} />;
};

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allContentfulArticle(sort: { fields: date, order: DESC } skip: $skip limit: $limit) {
      nodes {
        id
        title
        date(formatString: "DD MMMM YYYY")
        author
        category {
          name
        }
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
          file {
            url
          }
        }
        description {
          description
        }
      }
    }
  }
`;

export default Articles;